import React, { Suspense, lazy, useState } from 'react';
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-01";
import StickyMenu from "@containers/sticky-menu";
import PageHeader from "@containers/page-header/layout-01";
import PartnerArea from "@containers/partner/layout-01";
import FeatureGrid from "@components/feature-grid";
import DocIntro from "@containers/document-localization/doc-intro";
import Slider3D from "@containers/global/slider-3d";
import CtaArea from "@containers/cta/layout-02";
import DocTranslators from "@containers/document-localization/doc-translators";
import BoxSection from "@components/BoxSection/layout-one/layout-3";
import FaqArea from "@containers/faq/layout-03";
import CaseStudy from "@containers/global/case-study/layout-01";
import { caseStudiesData } from "@constants";
import ContactArea from "@containers/contact-us";
import QuoteForm from "@containers/translation-quote-form";
import UseScroll from "@containers/scroll";
const Footer = lazy(() => import("@layout/footer/layout-01"))
const DocumentLocalizationPage = ({ location, data }) => {
  const [showRestComponents, setShowRestComponents] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const content = normalizedData(data?.page.content || []);
  const globalContent = normalizedData(data?.allGeneral.nodes || []);
  UseScroll((scrollTop) => {
    if (scrollTop > 30) {
      setShowRestComponents(true);
    } else {
      setShowRestComponents(false);
    }
  }, setIsMobile);
  return (
    <Layout location={location}>
      <Seo
        title="Premium Document Localization Services by Andovar"
        description="Premium Document Localization Services – Andovar delivers precise translations of high-volume documents quickly and cost-effectively."
      />
      <Header
        data={{
          ...globalContent["menu"],
          ...data.site.siteMetadata,
          isMobile: isMobile,
        }}
      />
      {
        !isMobile && <StickyMenu data={content["stycky-menu-data"]} setShowRestComponents={setShowRestComponents} showRestComponents={showRestComponents} />
      }
      <main className="site-wrapper-reveal">
        <PageHeader data={content["document-localization-page-header-data"]} />
        <QuoteForm />
        {
          (!isMobile || (isMobile && showRestComponents)) && <>
            <PartnerArea data={content["doc-localization-logo"]} />
            <FeatureGrid data={content["document-localization-features"]} />
            <DocIntro data={content["document-intro"]} />
          </>
        }
        {
          showRestComponents && <>
            <BoxSection data={content["services-doc-localization"]} />
            <Slider3D data={content["document-applications"]} />
            <CtaArea data={content["cta-data"]} />
            <DocTranslators data={content["doc-translator-body"]} />
            <CaseStudy data={caseStudiesData} />
            <FaqArea data={content["doc-faq-body"]} />
            <ContactArea Layout={2} />
          </>
        }
      </main>
      {
        showRestComponents && <Suspense fallback={<div></div>}>
          <Footer data={{ ...data.site.siteMetadata }} />
        </Suspense>
      }
    </Layout>
  );
};

export const query = graphql`
  query documentLocalizationPageQuery {
    allGeneral {
      nodes {
        section
        ...HeaderOne
      }
    }
    site {
      ...Site
    }
    page(
      title: { eq: "document-localization" }
      pageType: { eq: "innerpage" }
    ) {
      content {
        ...PageContent
      }
    }
  }
`;

DocumentLocalizationPage.propTypes = {
  location: PropTypes.shape({}),
  data: PropTypes.shape({
    allGeneral: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        contact: PropTypes.shape({}),
      }),
    }),
    page: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

export default DocumentLocalizationPage;
